<template>
  <v-dialog v-model="dialog" max-width="586px" @keydown.esc="cancel" persistent>
    <v-form ref="form" class="pa-6 text-start">
      <div class="font-weight-medium mb-6" :class="$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-body-1'">
        Add Wallet
      </div>
      <div>
        Address
        <v-text-field
          :disabled="loading"
          placeholder="Wallet address"
          outlined
          dense
          v-model="address"
          class="mt-1 rounded-xl text-body-2"
          :rules="[$rules.required, $rules.contractAddress]"
        >
        </v-text-field>
        <div>
          Amount
          <v-text-field
            :disabled="loading"
            placeholder="Amount"
            outlined
            dense
            v-model="amount"
            class="mt-1 rounded-xl text-body-2"
            :rules="[$rules.required, $rules.positiveNumber]"
          >
          </v-text-field>
        </div>
        <div class="d-flex justify-space-between">
          <v-btn outlined rounded min-width="100px" @click="dialog = false">Cancel</v-btn>
          <v-btn class="primary" rounded min-width="100px" @click="save" :loading="loading">Save</v-btn>
        </div>
      </div>
    </v-form>
  </v-dialog>
</template>
      
<script>
import { mapState, mapActions } from 'vuex'
const { FixedNumber } = require('@ethersproject/bignumber')
export default {
  props: {
    state: Boolean,
    claim: Object,
  },
  components: {},
  computed: {
    ...mapState('auth', ['multiClaimHandler']),
  },
  methods: {
    ...mapActions('claimPools', ['addClaim', 'resetClaimInfo']),
    async save() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) return
        const res = await this.multiClaimHandler.addWinnerList(
          this.claim.id,
          [this.address],
          [FixedNumber.from(this.amount)],
          this.claim.decimals
        )
        if (res) {
          this.$emit('addWalletDone', { amount: this.amount, address: this.address })
          this.$alert.success('Add wallet successed')
        } else {
          this.$alert.error('Add wallet failed')
        }
        this.dialog = false
      } catch (e) {
        this.$alert.error(e.message)
      } finally {
        this.loading = false
      }
    },
    async resetDefault() {
      if (this.$refs.form && !this.loading) {
        await this.$refs.form.reset()
      }
    },
  },
  created() {
    this.resetDefault()
  },
  data() {
    return {
      amount: '',
      address: '',
      dialog: false,
      loading: false,
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
      this.resetDefault()
    },
  },
}
</script>